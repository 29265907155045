import React from "react";
import { HiOutlineHome } from "react-icons/hi2";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

export default function Floatingnavbar({ selectLink }) {

  let navigate = useNavigate();

  return (
    <div class="fixed z-40 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-0 lg:bottom-4 left-1/2">
      <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
        <button
          onClick={() => { navigate("/"); }}
          data-tooltip-target="tooltip-home"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 group"
        >
          <Link
            to="/"
            className={`mb-1 rounded-2xl text-gray-500 group-hover:text-[#e8b914] ${selectLink === "1"
              ? "text-basic"
              : "text-gray-500"
              }`}
          >
            <HiOutlineHome className="text-3xl" />
          </Link>
          <span class="sr-only">Home</span>
        </button>
        <div
          id="tooltip-home"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Home
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          onClick={() => { navigate("/categotyBooks"); }}
          data-tooltip-target="tooltip-wallet"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
        >
          <Link
            to="/categotyBooks"
            className={`mb-1 rounded-2xl text-gray-500 group-hover:text-[#e8b914] ${selectLink === "2"
              ? "text-basic"
              : "text-gray-500"
              }`}
          >
            <BiCategoryAlt className="text-3xl" />
          </Link>
          <span class="sr-only">Category</span>
        </button>
        <div
          id="tooltip-wallet"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Category
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <div class="flex items-center justify-center">
          <button
            onClick={() => { navigate("/search"); }}
            data-tooltip-target="tooltip-new"
            type="button"
            class="inline-flex items-center justify-center w-10 h-10 font-medium bg-basic rounded-full hover:bg-[#e8b914] group focus:ring-4 focus:ring-yellow-300 focus:outline-none"
          >
            <Link to="/search" className={` rounded-2xl text-gray-500 ${selectLink === "3" ? "text-yellow-100" : "text-white"}`}>
              <FiSearch className="text-2xl" />
            </Link>
            <span class="sr-only">Search</span>
          </button>
        </div>
        <div
          id="tooltip-new"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Search
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          onClick={() => { navigate("/favourite"); }}
          data-tooltip-target="tooltip-settings"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
        >
          <Link to="/favourite" className={`mb-1 rounded-2xl text-gray-500 group-hover:text-[#e8b914] ${selectLink === "4" ? "text-basic" : "text-gray-500"}`}>
            <FaRegHeart className="text-3xl" />
          </Link>
          <span class="sr-only">Love</span>
        </button>
        <div
          id="tooltip-settings"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Love
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          onClick={() => { navigate("/cart"); }}
          data-tooltip-target="tooltip-profile"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-gray-50 group"
        >
          <Link to="/cart" className={`mb-1 rounded-2xl text-gray-500 group-hover:text-[#e8b914] ${selectLink === "5" ? "text-basic" : "text-gray-500"}`}>
            <MdOutlineShoppingCart className="text-3xl" />
          </Link>
          <span class="sr-only">Cart</span>
        </button>
        <div
          id="tooltip-profile"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Cart
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  );
}

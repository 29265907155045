import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function Toast({ text, icon, visible }) {
  return (
    <div
      id="toast-simple"
      className={` z-10 flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-black font-bold text-lg bg-gray-100 divide-x divide-gray-200 rounded-lg shadow border border-yellow-500 border-dotted fixed top-[30px] right-16 ${visible ? 'toast-enter' : 'toast-exit'
        }`}
      role="alert"
    >
      <FontAwesomeIcon className="w-5 h-5 text-basic text-3xl" icon={icon} />
      <div className="ps-4 font-normal">{text}</div>
    </div>
  );
}

export default Toast;

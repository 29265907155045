import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FiUser, FiPhone, FiLock } from "react-icons/fi";
import { Helmet } from "react-helmet";
import Shoping from "../../Assets/Images/Mkanak-Logo.png";
import BackGround from "../../Assets/Images/light-patten.svg";
import sendData from "../../Api/Data"
import Cookies from 'js-cookie';
import { HiOutlineMail } from "react-icons/hi";


export default function Register() {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePassword = () => { setShowPassword((prevState) => !prevState); };
  const toggleConfirmPassword = () => { setShowConfirmPassword((prevState) => !prevState); };


  let [error, setError] = useState(null);

  let navigate = useNavigate();
  let [loaderbtn, setLoaderbtn] = useState(false);


  function sign_up(values) {
    values["userName"] = values.email;
    console.log(values);
    sendData("Auth/UserRegister", values, "POST").then((res) => {
      setLoaderbtn(false);
      if (res.status !== 200) {
        alert("Enter correct Data ");
      } else {
        Cookies.set('tokenv2', res.data.token, { expires: 15 });
        navigate("/");
      }
    })
  }


  function validationSchema() {
    const errors = Yup.object({
      firstName: Yup.string()
        .min(3, "يجب أن يكون الاسم الأول على الأقل 3 أحرف")
        .max(20, "يجب ألا يتجاوز الاسم الأول 20 حرفًا")
        .required("الاسم الأول مطلوب"),

      lasttName: Yup.string()
        .min(3, "يجب أن يكون اسم العائلة على الأقل 3 أحرف")
        .max(20, "يجب ألا يتجاوز اسم العائلة 20 حرفًا")
        .required("اسم العائلة مطلوب"),

      email: Yup.string()
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "يرجى إدخال عنوان بريد إلكتروني صالح"
        )
        .required("البريد الإلكتروني مطلوب"),

      password: Yup.string()
        .matches(
          /^.{8,}$/,
          "يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل، وتتضمن حرفًا واحدًا على الأقل، ورقمًا واحدًا وحرفًا خاصًا واحدًا"
        )
        .required("كلمة المرور مطلوب"),

      rePassword: Yup.string()
        .oneOf([Yup.ref("password")], "تأكيد كلمة المرور غير متطابق")
        .required("تأكيد كلمة المرور مطلوب"),
    });

    return errors;
  }


  let formik = useFormik({
    initialValues: {
      firstName: "",
      lasttName: "",
      email: "",
      password: "",
      rePassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoaderbtn(true);
      sign_up(values);
    },
  });

  const handlePhotoClick = () => {
    navigate("/");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mkanak | Register</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <img
        src={BackGround}
        alt="backGround"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          zIndex: "-1",
          top: "0",
          left: "0",
          right: "0",
          objectFit: "cover",
        }}
      />
      <div className="mt-4" dir="rlt">
        <div className="w-3/4 mx-auto">
          <h2 className="mb-5  text-gray-800 text-left mt-5 font-bold text-3xl" >انشاء حساب جديد </h2>
          <div className="flex flex-wrap mt-10 ">
            <div className="w-full lg:w-1/2 mb-0">
              <img onClick={handlePhotoClick} src={Shoping} className="w-full hover:opacity-70 " alt="" />
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-center ">
              <form onSubmit={formik.handleSubmit} className="bg-white rounded-lg">
                <label htmlFor="name" className="block text-gray-700 mb-2">الاسم الأول :</label>
                <div className="input-group my-2 flex items-center border rounded">
                  <span className="input-group-text p-2 bg-white border-l">
                    <FiUser
                      icon={faEnvelope}
                      className="text-basic text-lg"
                    />
                  </span>
                  <input
                    className="form-control w-full p-2 outline-none rounded-tl rounded-bl"
                    placeholder="ادخل اسمك"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="name"
                    name="firstName"
                    id="firstName"
                  />
                </div>


                {formik.errors.firstName && formik.touched.firstName && (
                  <p className="text-red-600 mb-2">
                    {formik.errors.firstName}
                  </p>
                )}


                <label htmlFor="name" className="block text-gray-700 mb-2">اسم العائلة  :</label>
                <div className="input-group my-2 flex items-center border rounded">
                  <span className="input-group-text p-2 bg-white border-l">
                    <FiUser
                      icon={faEnvelope}
                      className="text-basic text-lg"
                    />
                  </span>
                  <input
                    className="form-control w-full p-2 outline-none rounded-tl rounded-bl"
                    placeholder="ادخل اسم العائلة"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="name"
                    name="lasttName"
                    id="lasttName"
                  />
                </div>


                {formik.errors.lasttName && formik.touched.lasttName && (
                  <p className="text-red-600 mb-2">
                    {formik.errors.lasttName}
                  </p>
                )}


                <label htmlFor="email" className="block text-gray-700 mb-2">الايميل :</label>
                <div className="input-group my-2 flex items-center border rounded">
                  <span className="input-group-text p-2 bg-white border-l">
                    <HiOutlineMail className="text-basic text-xl" />


                  </span>
                  <input
                    className="form-control w-full p-2 outline-none rounded-tl rounded-bl"
                    placeholder="user@gmail.com"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    name="email"
                    id="email"
                  />
                </div>
                {formik.errors.email && formik.touched.email ? (
                  <p className="text-red-600 mb-2">
                    {formik.errors.email}
                  </p>
                ) : (
                  ""
                )}

                <label htmlFor="password" className="block text-gray-700 mb-2">الرقم السري :</label>
                <div className="input-group relative my-2 flex items-center border rounded">
                  <span className="input-group-text p-2 bg-white border-l">
                    <FiLock className="text-basic text-lg" />
                  </span>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control w-full p-2 outline-none rounded-tl rounded-bl"
                    placeholder="••••••••"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="password"
                    id="password"
                  />
                  {/* <span
                    className="p-2 cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2"
                    onClick={togglePassword}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        className="text-gray-700"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        className="text-gray-700"
                      />
                    )}
                  </span> */}
                </div>
                {formik.errors.password && formik.touched.password ? (
                  <p className="text-red-600 mb-2">
                    {formik.errors.password}
                  </p>
                ) : (
                  ""
                )}


                <label htmlFor="rePassword" className="block text-gray-700 mb-2"> تأكيد الرقم السري:</label>
                <div className="input-group relative my-2 flex items-center border rounded">
                  <span className="input-group-text p-2 bg-white border-l">
                    <FiLock className="text-basic text-lg" />
                  </span>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control w-full p-2 outline-none rounded-tl rounded-bl"
                    placeholder="••••••••"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="rePassword"
                    id="rePassword"
                  />
                  {/* <span
                    className="p-2 cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2"
                    onClick={toggleConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        className="text-gray-700"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        className="text-gray-700"
                      />
                    )}
                  </span> */}
                </div>

                {formik.errors.rePassword && formik.touched.rePassword && (
                  <p className="text-red-600 mb-2">
                    {formik.errors.rePassword}
                  </p>
                )}

                {/* <p className="font-bold text-[#696a6b]">By Signup, you agree to our <span className="text-basic">Terms of Service</span> & <span className="text-basic">Privacy Policy</span></p> */}

                <div className="text-right mt-4">
                  <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent rounded shadow-sm  mb-1 font-medium text-white bg-basic active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]"

                  >
                    {loaderbtn ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i> انشاء حساب
                      </>
                    ) : (
                      "انشاء حساب "
                    )}
                  </button>
                </div>


                <p className="font-medium mt-3 mb-14"> لديك حساب بالفعل؟
                  <Link to="/login" className="text-basic   ms-1 hover:underline ">
                    تسجيل دخول
                  </Link></p>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from 'axios'
import Cookies from 'js-cookie';


export default async function(path,data,method){

    const token = Cookies.get('tokenv2');

    let config = {
    method: method,
    maxBodyLength: Infinity,
    url: path ,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    data : data
    };

    return axios.request(config)
    .then((response) => {
        console.log("res",response);
        return (response);
    })
    .catch((error) => {
        console.log("error",error);
        return(error);
    });

}

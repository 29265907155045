
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdShoppingCart } from "react-icons/md";
import { faCartPlus, faHeart } from "@fortawesome/free-solid-svg-icons";
import { IoMdPerson } from "react-icons/io";
import { FaSignInAlt } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { Link } from "react-router-dom";
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import stage1 from "../Assets/Images/stage1.png";
import stage2 from "../Assets/Images/stage2.png";
import stage3 from "../Assets/Images/stage3.png";
import SearchInput from "./Details/SearchInput";
import { isAuthenticated } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Floatingnavbar from "./Details/Floatingnavbar";
import Carousel from "./Details/Caursol";
// import useInView from "./useInView";
import { MdOutlineShoppingCart } from "react-icons/md";
import Footer from "../components/Footer";

const Home = ({
  trendyBooks,
  setCurrentBook,
  recentBooks,
  allBooks,
  setSelectCategory,
  cartNumber
}) => {
  // ref
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);

  // states
  const [OpenLoqout, setOpenLoqout] = useState(false);
  const [books, setBooks] = useState("");
  const [malazm, setMalazm] = useState("");
  const [secondary, setSecondary] = useState("");
  const [middle, setMiddle] = useState("");
  const [primary, setPrimary] = useState("");

  // navigation
  const navigate = useNavigate();

  // useEffect
  useEffect(() => {
    getBooks();
    getMalazm();
    getSecondary();
    getMiddle();
    getPrimary();
  }, [allBooks]);

  // functions
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelectBook = (book) => {
    setCurrentBook(book);
  };

  const handeleProfileClick = () => {
    setOpenLoqout(!OpenLoqout);
  };

  const handleLogOut = () => {
    Cookies.remove("tokenv2");
    navigate("/login");
  };

  const getBooks = () => {
    if (allBooks) {
      const newArr = allBooks.filter((item) => item.categoryName == "كتب");
      setBooks(newArr);
    }
  };

  const getMalazm = () => {
    if (allBooks) {
      const newArr = allBooks.filter((item) => item.categoryName == "ملازم");
      setMalazm(newArr);
    }
  };

  const getSecondary = () => {
    if (allBooks) {
      const newArr = allBooks.filter(
        (item) => item.educationStageName == "الثانوية"
      );
      setSecondary(newArr);
    }
  };

  const getMiddle = () => {
    if (allBooks) {
      const newArr = allBooks.filter(
        (item) => item.educationStageName == "الاعدادية"
      );
      setMiddle(newArr);
    }
  };

  const getPrimary = () => {
    if (allBooks) {
      const newArr = allBooks.filter(
        (item) => item.educationStageName == "الابتدائية"
      );
      setPrimary(newArr);
    }
  };

  const cardVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.05,
    },
  };

  return (
    <>
      {/* <NavbarWeb /> */}

      <div className="bg-white min-h-screen p-4">
        {/* Header */}
        <div className="flex items-center justify-between mb-3">
          <img src={logo} alt="Logo" className="w-32 h-auto" />

          <div className="flex items-center relative">
            {OpenLoqout && (
              <div
                onClick={handleLogOut}
                className=" py-2 bg-gray-200 px-4 rounded-xl flex flex-row items-center cursor-pointer"
              >
                <CiLogout />
                <span className="mr-2">log Out </span>
              </div>
            )}
            {isAuthenticated() ? (
              <button
                onClick={handeleProfileClick}
                className="text-3xl mr-4 text-gray-500"
              >
                <IoMdPerson />
              </button>
            ) : (
              <Link to="/login" className="text-xl mr-4 text-gray-500">
                <FaSignInAlt />
              </Link>
            )}
            <Link to="/cart" className=" text-3xl mr-6 text-basic">
              <MdOutlineShoppingCart icon={faCartPlus} /> <span className=" absolute top-3 left-6 text-base bg-gray-300 text-black  w-[20px] text-center rounded-full  ">{cartNumber}</span>
            </Link>
          </div>
        </div>

        {/* search */}
        <SearchInput />

        {/* Filter Buttons */}
        <div className="flex mb-8 overflow-x-auto scrollbar-hide">
          <button
            onClick={() => scrollToSection(section1Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white"
          >
            رائج
          </button>
          <button
            onClick={() => scrollToSection(section2Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white "
          >
            اخر نزول
          </button>
          <button
            onClick={() => scrollToSection(section3Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white"
          >
            ملازم
          </button>
          <button
            onClick={() => scrollToSection(section4Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white"
          >
            كتب
          </button>
          <button
            onClick={() => scrollToSection(section5Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white"
          >
            ثانوي
          </button>
          <button
            onClick={() => scrollToSection(section6Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white"
          >
            اعدادي
          </button>
          <button
            onClick={() => scrollToSection(section7Ref)}
            className="px-6 py-2 bg-gray-200 rounded-full whitespace-nowrap mr-4 hover:bg-[#eab907] hover:text-white"
          >
            ابتدائي
          </button>
        </div>

        <Carousel />

        {/* Categories */}
        <div className="flex flex-row items-center justify-end mt-6">
          <Link to="/categotyBooks">
            <motion.p
              className="text-basic underline cursor-pointer"
              whileHover={{ scale: 1.1 }}
            >
              الكل
            </motion.p>
          </Link>
        </div>
        <div className="grid grid-cols-3 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          <motion.div
            onClick={() => {
              setSelectCategory({
                value: 3,
                label: "الابتدائية",
              });
              navigate("/categories/3");
            }}
            className="flex flex-col items-center p-2 card-item hover:cursor-pointer text-center"
            variants={cardVariants}
            whileHover="hover"
            initial="initial"
            animate="animate"
          >
            <motion.img
              src={stage1}
              alt="Stage 1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-[90px] object-fill border-2 border-basic rounded-full p-1"
            />
            <motion.p className="font-bold text-base mt-2 text-gray-700 ">
              المرحلة الابتدائية
            </motion.p>
          </motion.div>
          <motion.div
            onClick={() => {
              setSelectCategory({
                value: 4,
                label: "الاعدادية",
              });
              navigate("/categories/4");
            }}
            className="flex flex-col items-center p-2 card-item hover:cursor-pointer text-center"
            variants={cardVariants}
            whileHover="hover"
            initial="initial"
            animate="animate"
          >
            <motion.img
              src={stage2}
              alt="Stage 2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-[90px] object-fill border-2 border-basic rounded-full p-1"

            />
            <motion.p className="font-bold text-base mt-2 text-gray-700">
              المرحلة الاعدادية
            </motion.p>
          </motion.div>
          <motion.div
            onClick={() => {
              setSelectCategory({
                value: 5,
                label: "الثانوية",
              });
              navigate("/categories/5");
            }}
            className="flex flex-col items-center p-2 card-item hover:cursor-pointer text-center"
            variants={cardVariants}
            whileHover="hover"
            initial="initial"
            animate="animate"
          >
            <motion.img
              src={stage3}
              alt="Stage 3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-[90px] object-fill border-2 border-basic rounded-full p-1"
            />
            <motion.p className="font-bold text-base mt-2 text-gray-700">
              المرحلة الثانوية
            </motion.p>
          </motion.div>
        </div>

        {/* Horizontal Scrollable Cards */}
        <div ref={section1Ref} className="mb-8">
          <div className="flex flex-row items-center justify-between">
            <h2 className="mb-4 text-xl font-bold"> رائج </h2>
            <Link to="/search">
              <p className="text-basic underline">الكل </p>
            </Link>
          </div>
          <div className="flex overflow-x-auto pb-4 scrollbar-hide ">
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
              {trendyBooks
                ? trendyBooks.map((book) => (
                  <BookCard
                    key={book.bookId}
                    book={book}
                    handleSelectBook={handleSelectBook}
                  />
                ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <GrayCard key={index} />
                ))}
            </div>
          </div>
        </div>

        {/* Another Horizontal Scrollable Cards */}
        <div ref={section2Ref} className="mb-8">
          <div className="flex flex-row items-center justify-between">
            <h2 className="mb-4 text-xl font-bold"> اخر نزولا </h2>
            <Link to="/search">
              <p className="text-basic underline">الكل </p>
            </Link>
          </div>
          <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
            {recentBooks
              ? recentBooks.map((book) => (
                <BookCard
                  key={book.bookId}
                  book={book}
                  handleSelectBook={handleSelectBook}
                />
              ))
              : Array.from({ length: 6 }).map((_, index) => (
                <GrayCard key={index} />
              ))}
          </div>
        </div>

        {malazm && (
          <div ref={section3Ref} className="mb-8">
            <div className="flex flex-row items-center justify-between">
              <h2 className="mb-4 text-xl font-bold"> ملازم </h2>
              <Link to="/search">
                <p className="text-basic underline">الكل </p>
              </Link>
            </div>
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
              {malazm
                ? malazm.map((book) => (
                  <BookCard
                    key={book.bookId}
                    book={book}
                    handleSelectBook={handleSelectBook}
                  />
                ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <GrayCard key={index} />
                ))}
            </div>
          </div>
        )}

        {/* Another Horizontal Scrollable Cards */}
        {books && (
          <div ref={section4Ref} className="mb-8">
            <div className="flex flex-row items-center justify-between">
              <h2 className="mb-4 text-xl font-bold"> كتب </h2>
              <Link to="/search">
                <p className="text-basic underline">الكل </p>
              </Link>
            </div>
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
              {books
                ? books.map((book) => (
                  <BookCard
                    key={book.bookId}
                    book={book}
                    handleSelectBook={handleSelectBook}
                  />
                ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <GrayCard key={index} />
                ))}
            </div>
          </div>
        )}

        {/* Another Horizontal Scrollable Cards */}
        {secondary && (
          <div ref={section5Ref} className="mb-8">
            <div className="flex flex-row items-center justify-between">
              <h2 className="mb-4 text-xl font-bold"> كتب ثانوي </h2>
              <Link to="/search">
                <p className="text-basic underline">الكل </p>
              </Link>
            </div>
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
              {secondary
                ? secondary.map((book) => (
                  <BookCard
                    key={book.bookId}
                    book={book}
                    handleSelectBook={handleSelectBook}
                  />
                ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <GrayCard key={index} />
                ))}
            </div>
          </div>
        )}

        {middle && (
          <div ref={section6Ref} className="mb-8">
            {middle.length ? (
              <h2 className="mb-4 text-xl font-bold"> كتب اعدادي </h2>
            ) : (
              ""
            )}
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
              {middle
                ? middle.map((book) => (
                  <BookCard
                    key={book.bookId}
                    book={book}
                    handleSelectBook={handleSelectBook}
                  />
                ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <GrayCard key={index} />
                ))}
            </div>
          </div>
        )}

        {primary && (
          <div ref={section7Ref} className="mb-8">
            {primary.length ? (
              <h2 className="mb-4 text-xl font-bold"> كتب ابتدائي </h2>
            ) : (
              ""
            )}
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
              {primary
                ? primary.map((book) => (
                  <BookCard
                    key={book.bookId}
                    book={book}
                    handleSelectBook={handleSelectBook}
                  />
                ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <GrayCard key={index} />
                ))}
            </div>
          </div>
        )}

        {/* Bottom Navigation */}
        {/* <Navbar selectLink="1" /> */}
        <Floatingnavbar selectLink="1" />
        <Footer/>
      </div>
    </>
  );
};

// const Card = ({ imageUrl, title }) => {
//   return (
//     <Link to="/bookdetails">
//       <div className="min-w-[150px] max-w-[150px] hover:opacity-70">
//         <img src={`data:image/png;base64,${imageUrl}`} alt={title} className="w-full h-32 rounded-lg mb-2" />
//         <h3 className="text-center">{title}</h3>
//       </div>
//     </Link>
//   );
// };

const BookCard = ({ book, handleSelectBook }) => {

  return (
    <div
      className="mr-4"
      onClick={() => {
        handleSelectBook(book);
      }}
    >
      <Card
        imageUrl={book.image}
        title={book.title}
        price={book.price}
        bookId={book.bookId}
        favourite={book.favourite}
        description={book.bookDescription}
        levelName={book.levelName}
        educationStageName={book.educationStageName}
        isForFirstTerm={book.isForFirstTerm}
        id={book.bookId}
        quan = {book.quantityAvailable}
        categoryName={book.categoryName}
      />
    </div>
  );
};

const Card = ({
  imageUrl,
  title,
  price,
  favourite,
  description,
  id,
  quan,
  categoryName
}) => {
  const bookurl = "https://mkanaak-001-site4.htempurl.com/" + imageUrl.replace(/\\/g, "/");
  return (
    <Link to={`/bookdetails/${id}`}>
      <motion.div
        className="flex flex-col items-center p-1 border rounded-lg shadow-sm cursor-auto w-[170px] hover:cursor-pointer"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <img
          loading="lazy"
          src={bookurl}
          alt={title}
          className="w-full object-cover rounded-md mb-2 min-h-[150px]"
        />
        <h3 className="text-lg font-semibold ">{title}</h3>
        <p className=" text-base text-gray-700 mb-1">{description}</p>
        {/* <p className="text-base text-gray-700 mb-2">
          {levelName} {educationStageName}{" "}
          {isForFirstTerm ? "الترم الأول" : " الترم الثاني"}
        </p> */}
        <div className="mb-2">
          <p className="text-base text-red-500 ">
            {(quan<1)&&"نفذت الكمية"}
          </p>
          <p className="text-base text-green-500 ">
            {(quan>0)&&" متوفر"}
          </p>
        </div>
        <p className="text-sm text-gray-700 lg:text-right mb-2 flex-1 justify-between ">
              {/* {categoryName != "ملازم" ? <><span className="line-through text-red-500"> {price} جنيه </span> <span className=" mx-3"> {(price* 0.9).toFixed(2)} جنيه </span></>
              :<><span className="font-bold">السعر</span> : <span className="mx-3"> {price} جنيه </span></>} */}
              <span className="font-bold">السعر</span> : <span className="mx-3"> {price} جنيه </span>
        </p>
        <div className="flex justify-between w-full">
          <button>
            <FontAwesomeIcon
              icon={faHeart}
              className={` text-xl ${favourite ? " text-basic text-xl" : "text-gray-400 text-xl"
                }`}
            />
          </button>
          <MdShoppingCart className="text-basic text-xl" />
        </div>
      </motion.div>
    </Link>
  );
};

const GrayCard = () => {
  return (
    <div className="min-w-[150px] max-w-[150px] mr-4">
      <div className="w-full h-32 bg-gray-300 rounded-lg mb-2"></div>
      <h3 className="text-center text-gray-400">تحميل...</h3>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import SearchInput from "./Details/SearchInput";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import getData from "../Api/Data";
import { isAuthenticated } from "../utils/auth";
import Floatingnavbar from "./Details/Floatingnavbar";
import { MdOutlineShoppingCart } from "react-icons/md";
import Toast from "./Details/Toast";
import Modal from "./Modal";
import Footer from "../components/Footer";

const Favourite = ({
  favourtieBooks,
  setFavourtieBooks,
  setCartItems,
  cartNumber,
}) => {
  const [loaderbtn, setLoaderbtn] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setsShowModal] = useState(false);

  useEffect(() => {
    if (!isAuthenticated()) {
      setsShowModal(true);
    }
  }, []);

  const handleRemove = (bookId) => {
    if (!loaderbtn) {
      console.log(favourtieBooks);
      setLoaderbtn(true);
      const token = Cookies.get("tokenv2");
      if (token) {
        const decodedToken = jwtDecode(token);
        const uid = decodedToken.uid;
        getData(`FavoriteBooks/${uid}/remove/${bookId}`, {}, "DELETE").then(
          (res) => {
            if (res.status === 200) {
              const updatedFavouriteBooks = favourtieBooks.filter(
                (book) => book.bookId !== bookId
              );
              setFavourtieBooks(updatedFavouriteBooks);
            }
            setLoaderbtn(false);
          }
        );
      }
    }
  };

  const handleAddToCard = (book) => {
    console.log(book);
    if (book.quantityAvailable >0){
    setCartItems((prevItems) => {
      const isBookInCart = prevItems.some(
        (item) => item.bookId === book.bookId
      );
      if (!isBookInCart) {
        return [...prevItems, book];
      }
      return prevItems;
    });
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
    } else {
      alert("نفذت الكمية ");
    }
  };

  return (
    <div className="bg-white min-h-screen p-4">
      {showToast && (
        <Toast
          text="تمت الإضافة إلى السلة بنجاح"
          icon={faCartPlus}
          visible={showToast}
        />
      )}
      <div className="flex items-center justify-between mb-3">
        <img src={logo} alt="Logo" className="w-32 h-auto" />
        <div className="flex items-center relative">
          <Link to="/cart" className=" text-3xl mr-4 text-basic">
            <MdOutlineShoppingCart icon={faCartPlus} />{" "}
            <span className=" absolute top-3 left-6 text-base bg-gray-300 text-black w-[20px] text-center rounded-full ">
              {cartNumber}
            </span>
          </Link>
        </div>
      </div>
      <SearchInput />
      {isAuthenticated() ? (
        <div className="space-y-4 mb-[80px]">
          {favourtieBooks.length ? (
            favourtieBooks.map((book) => (
              <WishlistItem
                key={book.bookId}
                book={book}
                handleRemove={handleRemove}
                handleAddToCard={handleAddToCard}
              />
            ))
          ) : (
            <div className=" absolute top-60 left-0 flex w-full justify-center">
              {" "}
              <p className="text-2xl text-gray-500 font-extrabold  ">
                اضف عناصر الي المفضلة 📚{" "}
              </p>{" "}
            </div>
          )}
        </div>
      ) : (
        <div>
          {showModal && <Modal setsShowModal={setsShowModal} />}
          <div className="flex justify-center items-center mt-20 flex-col ">
            <p className="text-3xl">من فضلك سجل اولا</p>

            <Link to="/register" className="mt-4">
              <button className="w-full py-2 px-4 border border-transparent rounded shadow-sm  mb-1 font-medium text-white bg-basic active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]">
                سجل حسابك الان
              </button>
            </Link>
          </div>
        </div>
      )}
      <Floatingnavbar selectLink="4" />
    </div>
  );
};

const WishlistItem = ({ book, handleRemove, handleAddToCard }) => {
  const bookurl =
    "https://mkanaak-001-site4.htempurl.com/" + book.image.replace(/\\/g, "/");
  return (
    <AnimatePresence>
      <motion.div
        className="flex items-center border p-4 rounded-lg w-full md:w-3/4 lg:w-1/2"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.02, boxShadow: "0px 10px 30px rgba(0,0,0,0.1)" }}
      >
        <img
          loading="lazy"
          src={bookurl}
          alt={book.title}
          className="w-20 h-20 rounded-lg mr-4"
        />
        <div className="flex-1 mr-4">
          <h2 className="font-bold text-lg">{book?.authorName}</h2>
          <p className="text-gray-500">{book?.bookDescription} </p>
        </div>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => handleAddToCard(book)}
          className="text-basic text-lg"
        >
          <FontAwesomeIcon icon={faCartPlus} />
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => handleRemove(book.bookId)}
          className="text-gray-500 text-lg mr-4"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </motion.button>
      </motion.div>
    </AnimatePresence>
  );
};

export default Favourite;

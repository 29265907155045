import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/Mkanak-Logo-small.png";
import { FaSignInAlt } from "react-icons/fa";
import stage1 from "../../Assets/Images/stage1.png";
import stage2 from "../../Assets/Images/stage2.png";
import stage3 from "../../Assets/Images/stage3.png";
import { isAuthenticated } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import Floatingnavbar from "./Floatingnavbar";
import { motion } from "framer-motion";
import { MdOutlineShoppingCart } from "react-icons/md";

const CategotyBooks = ({ setSelectCategory, cartNumber }) => {
  const navigate = useNavigate();

  const cardVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    hover: {
      scale: 1.05,
    },
  };

  return (
    <div className="bg-white min-h-screen p-4">
      {/* Header */}
      <div className="flex items-center justify-between mb-3">
        <img src={logo} alt="Logo" className="w-32 h-auto" />
        <div className="flex items-center relative">
          <Link to="/cart" className=" text-3xl mr-4 text-basic">
            <MdOutlineShoppingCart icon={faCartPlus} /> <span className=" absolute top-3 left-6 text-base bg-gray-300 text-black  w-[20px] text-center rounded-full  ">{cartNumber}</span>
          </Link>
          {!isAuthenticated() && (
            <Link to="/login" className="text-xl mr-4 text-gray-500">
              <FaSignInAlt />
            </Link>
          )}
        </div>
      </div>

      {/* Book Cards */}
      <p className="text-[30px] text-gray-700 text-right lg:mr-[120px] mb-7 font-bold">
        الاقسام{" "}
      </p>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <motion.div
          onClick={() => {
            setSelectCategory({
              value: 3,
              label: "الابتدائية",
            });
            navigate("/categories/3");
          }}
          className="flex flex-col items-center p-2 card-item hover:cursor-pointer"
          variants={cardVariants}
          whileHover="hover"
          initial="initial"
          animate="animate"
        >
          <motion.img
            src={stage1}
            alt="Stage 1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="border-2 border-basic rounded-full p-1"

          />
          <motion.p className="font-bold text-xl mt-2">المرحلة الابتدائية</motion.p>
        </motion.div>
        <motion.div
          onClick={() => {
            setSelectCategory({
              value: 4,
              label: "الاعدادية",
            });
            navigate("/categories/4");
          }}
          className="flex flex-col items-center p-2 card-item hover:cursor-pointer"
          variants={cardVariants}
          whileHover="hover"
          initial="initial"
          animate="animate"
        >
          <motion.img
            src={stage2}
            alt="Stage 2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="border-2 border-basic rounded-full p-1"
          />
          <motion.p className="font-bold text-xl mt-2">المرحلة الاعدادية</motion.p>
        </motion.div>
        <motion.div
          onClick={() => {
            setSelectCategory({
              value: 5,
              label: "الثانوية",
            });
            navigate("/categories/5");
          }}
          className="flex flex-col items-center p-2 card-item hover:cursor-pointer"
          variants={cardVariants}
          whileHover="hover"
          initial="initial"
          animate="animate"
        >
          <motion.img
            src={stage3}
            alt="Stage 3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="border-2 border-basic rounded-full p-1"

          />
          <motion.p className="font-bold text-xl mt-2">المرحلة الثانوية</motion.p>
        </motion.div>
      </div>

      {/* Bottom Navigation */}
      <Floatingnavbar selectLink="2" />
    </div>
  );
};

export default CategotyBooks;

import React, { useState, useEffect } from "react";
import Shoping from "../Assets/Images/Mkanak-Logo.png";
import Cookies from 'js-cookie';
import sendData from "../Api/Data"

function Modal({ setsShowModal }) {

  const [isVisible, setIsVisible] = useState(false);
  const [haveAccount, setHaveAccount] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  useEffect(() => {
    setIsVisible(true); // Trigger the enter animation
  }, []);

  const handleClose = () => {
    setIsVisible(false); // Trigger the exit animation
    setTimeout(() => setsShowModal(false), 300); // Wait for animation to finish before hiding
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    if (haveAccount) {
      const Data = {
        email,
        password,
      };
      sendData("Auth/UserLogin", Data, "POST").then((res) => {
        if (res.status !== 200) {
          alert(res.response.data);
        } else {
          Cookies.set('tokenv2', res.data.token, { expires: 15 });
          setsShowModal(false);
        }
      })
    } else {
      if (password !== repassword) {
        alert("Passwords do not match");
        return;
      }
      const Data = {
        firstName:firstName,
        lasttName:lastName,
        email:email,
        userName: email,
        password:password
      };
      sendData("Auth/UserRegister", Data, "POST").then((res) => {
        console.log(res);
        if (res.status !== 200) {
          alert(res.response.data);
        } else {
          Cookies.set('tokenv2', res.data.token, { expires: 15 });
          setsShowModal(false);
        }
      })
    }
  };

  return (
    <div>
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={`modal-backdrop ${isVisible ? "enter" : "exit"
          } flex overflow-y-auto overflow-x-hidden fixed top-20 right-0 left-0 z-50 justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div className="relative p-4 w-[400px] max-w-md max-h-full pt-5">
          <div className="relative bg-white rounded-lg shadow-2xl border  border-yellow-600 kk">
            <div className="flex items-center justify-between p-4 md:p-5 rounded-t ">
              <h3 className="text-lg font-semibold text-gray-900 ">
                سجل الدخول اولا و تصفح كل الكتب
              </h3>
              <button
                type="button"
                className="end-2.5  bg-red-500 text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                data-modal-hide="authentication-modal"
                onClick={handleClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <div className="w-full lg:w-1/2 mb-0 flex justify-center align-middle">
              <img src={Shoping} className="w-2/6 hover:opacity-70 border  border-red-800 " alt="" />
            </div> */}
            <div className="p-4 md:p-5">
              <form className="space-y-4" onSubmit={handleSubmit}>
                {!haveAccount && (
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      الاسم الأول
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5"
                      placeholder="ادخل اسمك"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                )}
                {!haveAccount && (
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      اسم العائلة
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5"
                      placeholder="ادخل اسم العائلة"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                )}
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    الايميل
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5"
                    placeholder="name@company.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    الرقم السري
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                {!haveAccount && (
                  <div>
                    <label
                      htmlFor="repassword"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      تأكيد الرقم السري
                    </label>
                    <input
                      type="password"
                      name="repassword"
                      id="repassword"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5"
                      value={repassword}
                      onChange={(e) => setRePassword(e.target.value)}
                      required
                    />
                  </div>
                )}
                <button
                  type="submit"
                  // className="w-full text-white bg-yellow-600 hover:bg-yellow-600  focus:outline-none focus:ring-yellow-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  className="w-full ml-4 py-[8px] px-4 border border-transparent rounded-lg shadow-sm mb-1 font-bold text-sm text-white bg-basic hover:bg-basic active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]"

                >
                  تسجيل الدخول
                </button>
                {haveAccount && (
                  <div className="text-sm font-medium text-gray-500">
                    ليس لديك حساب؟ {" "}
                    <div
                      className="text-basic hover:underline inline-block cursor-pointer"
                      onClick={() => setHaveAccount(false)}
                    >
                      انشاء حساب جديد
                    </div>
                  </div>
                )}
                {!haveAccount && (
                  <div className="text-sm font-medium text-gray-500">
                    لديك حساب؟ {" "}
                    <div
                      className="text-basic hover:underline inline-block cursor-pointer"
                      onClick={() => setHaveAccount(true)}
                    >
                      تسجيل دخول
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;

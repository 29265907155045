import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPlus,
  faMinus,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import { Link, useNavigate } from "react-router-dom";
import SearchInput from './Details/SearchInput';
import CustomSelect from "../components/CustomSelect";
import { isAuthenticated } from "../utils/auth";
import Floatingnavbar from "./Details/Floatingnavbar";
import { MdOutlineShoppingCart } from "react-icons/md";
import Modal from './Modal';
import OrderModal from "./OrderModal";

const Cart = ({ cartItems, setCartItems, cartNumber,refreshData }) => {

  const navigate = useNavigate();

  const [orderTotal, setOrderTotal] = useState(0);
  const [showModal, setsShowModal] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [showOrderForm, setShowOrderForm] = useState(false); 

  useEffect(() => {
    calculateOrder();
    // getallCities();
    // getallStates();
  }, [cartItems])

  useEffect(() => {
    if (!isAuthenticated()) {
      setsShowModal(true)
    }
  }, [])

  const calculateOrder = () => {
    const calOrderTotal = cartItems.reduce(
      (total, item) => total + item.price * (item.quantity ?? 1),
      0
    );

    const discountTotal = cartItems.reduce(
      (total, item) =>{
        if (item.categoryName != "ملازم"){
          return (total + item.price * 0.1 * (item.quantity ?? 1))   
        }else{
          return (total);
        }
      }
      ,
      0
    );
    setOrderTotal(calOrderTotal);
    // setDiscount(discountTotal);
    setDiscount(0);
  }

  const IncreaseItem = (id) => {
    setCartItems(prevItems => {

      const itemIndex = prevItems.findIndex(cartItem => cartItem.bookId == id);

      if (itemIndex !== -1) {

        const newItems = [...prevItems];
        const item = newItems[itemIndex];

        if (item.hasOwnProperty('quantity')) {

          item.quantity++;
        } else {

          item.quantity = 2;
        }

        return newItems;
      }
    });

  }

  const DecreaseItem = (id) => {
    setCartItems(prevItems => {

      const itemIndex = prevItems.findIndex(cartItem => cartItem.bookId == id);

      if (itemIndex !== -1) {

        const newItems = [...prevItems];
        const item = newItems[itemIndex];

        if (item.hasOwnProperty('quantity')) {
          if (item.quantity < 2) {
            item.quantity = 1;
          } else {
            item.quantity -= 1;
          }
        } else {

          item.quantity = 1;
        }

        return newItems;
      }
    });

  }

  const DeleteItem = (id) => {
    setCartItems(prevItems => {
      // Filter out the item with the given id
      return prevItems.filter(cartItem => cartItem.bookId !== id);
    });
  }

  // const getallStates = () => {
  //     sendData("State", {}, "GET").then((res) => {
  //       if (res.status !== 200) {
  //         console.log("delevery states failed");
  //       } else {
  //         const newData = (res.data).map(data => ({
  //           value: data.stateId,
  //           label: data.name,
  //           deliveryPrice: data.deliveryPrice
  //         }));
  //         setStateOprions(newData);
  //         setSelectedState(newData[0].value);
  //         setDeleveryState(newData[0].deliveryPrice);
  //       }
  //     });
  // };

  // const getallCities = () => {
  //     sendData("City", {}, "GET").then((res) => {
  //       if (res.status !== 200) {
  //         console.log("get cities failed");
  //       } else {
  //           const newData = (res.data).map(data => ({
  //             value: data.cityId,
  //             label: data.name,
  //             deliveryPrice: data.deliveryPrice
  //           }));
  //           setCityOprions(newData);
  //           setSelectedCity(newData[0].value);
  //           setDeleveryCitie(newData[0].deliveryPrice);
  //       }
  //     });
  // };

  const handleSubmit = () => {
    if (cartItems.length) {
      // const params = { cartItems }
      // navigate('/orderform', { state: params });
      setShowOrderForm(true);
    } else {
      alert("اضف عناصر الي السلة ")
    }

    //   const token = Cookies.get('token');
    //   const decodedToken = jwtDecode(token);
    //   const uid = decodedToken.uid;
    //   const userName = decodedToken.email;
    //   const orderItems = cartItems.map(item => (
    //         {
    //             bookId: item.bookId,
    //             quantity: item.quantity !== undefined ? item.quantity : 1
    //         }
    // ));
    //   const data ={phoneNumber:numberone, phoneNumber2:numberTwo, street:streat, userId:uid, stateId:selectedState, cityId:selectedCity, orderItems,userName}
    //   console.log(data);
    //   sendData("Order", data, "POST").then((res) => {
    //     if (res.status !== 200) {
    //       alert("الرجاء ادخال بيانات صحيحة")
    //     } else {
    //       alert("تم ارسال الطلب بنجاح ")
    //     }
    //   });
  };

  // const tax = 9;

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      {/* Header */}
      <div className="flex items-center justify-between mb-3">
        <img src={logo} alt="Logo" className="w-32 h-auto" />
        <Link to="/past-orders" className=" text-sm py-2 px-4 border border-transparent rounded shadow-sm text-white font-medium bg-basic hover:bg-yellow-500 active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]">
            <div>الطلبات السابقة </div>
        </Link>
        <div className="flex items-center relative">
          <Link to="/cart" className=" text-3xl mr-4 text-basic">
            <MdOutlineShoppingCart icon={faCartPlus} /> <span className=" absolute top-3 left-6 text-base bg-gray-300 text-black  w-[20px] text-center rounded-full  ">{cartNumber}</span>
          </Link>
        </div>
      </div>
      <SearchInput />

      {/* Cart Items */}
      <div className="flex justify-center ">
        {/* <div className="grid grid-cols-1 gap-4 w-full max-w-md  lg:max-w-lg border border-red-500 "> */}
        <div className="grid grid-cols-1 gap-4 w-full ">

          {cartItems.length ? cartItems.map((item) => {
          const bookurl = "https://mkanaak-001-site4.htempurl.com/" + item.image.replace(/\\/g, "/");
          return(
            <div
              key={item.id}
              className="flex items-center p-4 bg-white rounded-lg shadow"
            >
              <img
                loading="lazy"
                src={bookurl}
                alt={item.name}
                className="w-20 h-20 rounded-lg"
              />
              <div className="flex-1 mr-4">
                <h3 className="text-lg font-semibold">{item.name}</h3>
                <p className="text-gray-600">{item.price} جنيه</p>
                <button onClick={() => { DeleteItem(item.bookId) }} className="mt-2 p-2 text-gray-700">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
              <div className="flex flex-col items-center">
                <button onClick={() => { IncreaseItem(item.bookId) }} className="p-2 text-basic">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <span className="mx-2">{item.quantity ?? 1}</span>
                <button onClick={() => { DecreaseItem(item.bookId) }} className="p-2 text-basic">
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </div>
            </div>
          )}
        )
            : <div className=" absolute top-[450px] left-0 flex w-full justify-center items-center"> <p className="text-2xl text-gray-500 font-extrabold  "> اضف عناصر الي السلة 🛒</p> </div>
          }
        </div>
      </div>

      {
        isAuthenticated() ?
          //  main page  
          <div className="mt-8 p-4 bg-white rounded-lg shadow mb-[80px]">
            <div className="flex justify-between mb-2">
              <span>الطلب </span>
              <span>{orderTotal?.toFixed(2)} جنيه </span>
            </div>
            <div className="flex justify-between mb-2">
              <span>الخصم </span>
              <span>{discount?.toFixed(2)} جنيه </span>
            </div>
            {/* <div className="flex justify-between mb-2">
          <span>مصاريف الشحن </span>
          <span>{deliveryCharge} جنيه </span>
        </div> */}
            {/* <div className="flex justify-between mb-4 font-bold">
          <span>Total</span>
          <span>{total.toFixed(2)} جنيه </span>
        </div> */}
            <div className="flex justify-between mb-4 font-bold">
              <span> المدفوع </span>
              <span>{(orderTotal-discount)?.toFixed(2)} جنيه </span>
            </div>
            <button onClick={handleSubmit} className="w-full py-2 px-4 border border-transparent rounded shadow-sm  mb-4 font-medium text-white bg-basic hover:bg-yellow-500 active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]">

              اطلب الان
            </button>
            {showOrderForm && (
              <OrderModal setsShowModal={setShowOrderForm} cartItems={cartItems} refreshData={refreshData} orderTotal={orderTotal} discount={discount} />
            )}
          </div>
          // not login
          :
          <div>
            {showModal && <Modal setsShowModal={setsShowModal} />}
            <div className='flex justify-center items-center mt-6 flex-col '>
              <p className="text-3xl">من فضلك سجل اولا</p>

              <Link to="/register" className="mt-4">
                <button className="w-full py-2 px-4 border border-transparent rounded shadow-sm  mb-1 font-medium text-white bg-basic active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]">
                  سجل حسابك الان
                </button>
              </Link>
            </div>
          </div>
      }
      {/* Bottom Navigation */}
      <Floatingnavbar selectLink="5" />
    </div>
  );
};

export default Cart;

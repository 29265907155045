import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import { MdOutlineShoppingCart } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { motion, useAnimation } from "framer-motion";
import sendData from '../Api/Data';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import CustomSelect from '../components/CustomSelect';

function Orderform({refreshData}) {

    const location = useLocation();
    const { cartItems } = location.state;

    const [orderTotal,setOrderTotal] = useState(0);

    const [deleveryState, setDeleveryState] = useState(0);
    const [deleveryCitie, setDeleveryCitie] = useState(0);
    const [stateOprions, setStateOprions] = useState(null);
    const [cityOprions, setCityOprions] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [streat, setStreat] = useState("");
    const [userName, setUserName] = useState("");
    const [numberone, setNumberone] = useState("");
    const [numberTwo, setNumberTwo] = useState("");


    useEffect(()=>{
        calculateOrder();
        getallCities();
        getallStates();
      },[cartItems]);

    const calculateOrder = () => {
      const calOrderTotal = cartItems.reduce(
        (total, item) => total + item.price * (item.quantity ?? 1),
        0
      );
      setOrderTotal(calOrderTotal);
    }

    const getallStates = () => {
        sendData("State", {}, "GET").then((res) => {
          if (res.status !== 200) {
            console.log("delevery states failed");
          } else {
            const newData = (res.data).map(data => ({
              value: data.stateId,
              label: data.name,
              deliveryPrice: data.deliveryPrice
            }));
            setStateOprions(newData);
            setSelectedState(newData[0].value);
            setDeleveryState(newData[0].deliveryPrice);
          }
        });
    };
  
    const getallCities = () => {
        sendData("City", {}, "GET").then((res) => {
          if (res.status !== 200) {
            console.log("get cities failed");
          } else {
              const newData = (res.data).map(data => ({
                value: data.cityId,
                label: data.name,
                deliveryPrice: data.deliveryPrice
              }));
              setCityOprions(newData);
              setSelectedCity(newData[0].value);
              setDeleveryCitie(newData[0].deliveryPrice);
          }
        });
    };

    const handleSubmit = () => {
        if(numberone&&numberTwo&&streat&&userName&&selectedState&&selectedCity){
        const token = Cookies.get('tokenv2');
        const decodedToken = jwtDecode(token);
        const uid = decodedToken.uid;
        const orderItems = cartItems.map(item => (
            {
              bookId: item.bookId,
              quantity: item.quantity !== undefined ? item.quantity : 1
            }
        ));
        const data ={phoneNumber:numberone, phoneNumber2:numberTwo, street:streat, userId:uid, stateId:selectedState, cityId:selectedCity, orderItems,userName}

        sendData("Order", data, "POST").then((res) => {
          if (res.status !== 200) {
            alert("الرجاء ادخال بيانات صحيحة")
          } else {
            console.log(res);
            alert("تم ارسال الطلب بنجاح ");
          }
        });

        }else{
            alert("ادخل كل البيانات ")
        }
    };

    const pageVariants = {
      initial: {
        opacity: 0,
        y: "+100vh",
      },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          ease: "easeOut",
        },
      },
    };


    const deliveryCharge = deleveryCitie + deleveryState ;
    const total = orderTotal + deliveryCharge;

  return (
      <motion.div
      className="min-h-screen p-4 bg-gray-100"
      initial="initial"
      animate="animate"
      variants={pageVariants}
      >
        <div className="flex items-center justify-between mb-3">
            <img src={logo} alt="Logo" className="w-32 h-auto" />
        </div>
        <form class="max-w-md mx-auto m-5 p-10 border border-yellow-500 rounded-[50px]">
            <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-8 group">
                    <input value={userName} onChange={(e)=>{setUserName(e.target.value)}} type="text" name="floating_Name" id="floating_Name" class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-yellow-600 peer" placeholder=" " required />
                    <label for="floating_Name" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-yellow-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">الاسم </label>
                </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-8 group">
                    <input value={numberone} onChange={(e)=>{setNumberone(e.target.value)}} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="floating_phone" id="floating_phone" class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-yellow-600 peer" placeholder=" " required />
                    <label for="floating_phone" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-yellow-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> رقم الهاتف </label>
                </div>
                <div class="relative z-0 w-full mb-8 group">
                    <input value={numberTwo} onChange={(e)=>{setNumberTwo(e.target.value)}} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="floating_phone" id="floating_phone" class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-yellow-600 peer" placeholder=" " required />
                    <label for="floating_phone" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-yellow-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> رقم اخر للتواصل  </label>
                </div>
            </div>
            <div class="relative z-0 w-full mb-8 group">
                <input value={streat} onChange={(e)=>{setStreat(e.target.value)}} type="text" name="floating_address" id="floating_address" class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-yellow-600 peer" placeholder=" " required />
                <label for="floating_address" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-yellow-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">العنوان بالتفصيل  </label>
            </div>
            <div className='flex'>
               {stateOprions && <div className=" w-[50%]  mt-4" >  <CustomSelect key="0" data={stateOprions} setoption={setSelectedState} setDelevery={setDeleveryState} refreshData={refreshData} /> </div> }
               {cityOprions && <div className=" w-[50%] mt-4" > <CustomSelect key="1" data={cityOprions} setoption={setSelectedCity} setDelevery={setDeleveryCitie} refreshData={refreshData} /> </div> }
            </div>

            {/* price */}
        </form>
        <div className="mt-8 p-4 bg-white rounded-lg shadow mb-[80px]">
            <div className="flex justify-between mb-2">
                <span>الطلب </span>
                <span>{orderTotal?.toFixed(2)} جنيه </span>
            </div>
            <div className="flex justify-between mb-2">
                <span>مصاريف الشحن </span>
                <span>{deliveryCharge} جنيه </span>
            </div>
            <div className="flex justify-between mb-4 font-bold">
                <span>Total</span>
                <span>{total.toFixed(2)} جنيه </span>
            </div>
            <button onClick={handleSubmit} className="w-full py-2 px-4 border border-transparent rounded shadow-sm  mb-4 font-medium text-white bg-[#e8b914] hover:bg-yellow-500 active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]">
                اطلب الان 
            </button>
      </div> 
    </motion.div>
  )
}

export default Orderform

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Categories from "./Pages/Categories";
import Favourite from "./Pages/Favourite";
import Cart from "./Pages/Cart";
import Search from "./Pages/Search";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import BookDetail from "./Pages/Details/Bookdetails";
import CategotyBooks from "./Pages/Details/CategoryBooks";
import getData from "./Api/Data";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import RestPassword from "./Pages/Auth/RestPassword";
import ChangePassword from "./Pages/Auth/ChangePassword";
import VerifyResetCode from "./Pages/Auth/VerifyResetCode";
import Profile from "./Pages/Auth/Profile";
import PublicRoute from "./utils/PublicRoute";
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import Orderform from "./Pages/Orderform";
import Payemnt from "./Pages/Payemnt";
import CardPayemnt from "./Pages/CardPayemnt";
import PastOrders from "./Pages/PastOrders";

const App = () => {
  // states
  const [trendyBooks, setTrendyBooks] = useState("");
  const [recentBooks, setRecentBooks] = useState("");
  const [allBooks, setAllBooks] = useState("");
  const [favourtieBooks, setFavourtieBooks] = useState("");
  const [cartItems, setCartItems] = useState([]);

  const [author, setAuthor] = useState([]);
  const [category, setCategory] = useState([]);
  const [subject, setSubject] = useState([]);
  const [level, setLevel] = useState([]);
  const [educationStage, setEducationStage] = useState([]);
  const [SelectCategory, setSelectCategory] = useState([]);

  const [currentBook, setCurrentBook] = useState("");

  const [refrechAllWeb, setRefrechAllWeb] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  // useEffect
  useEffect(() => {
    // First, fetch all books
    getAllBooks();
    getEducationStage();
    getSubject();
    getLevel();
    getCategory();
    getAuthor();
  }, [refrechAllWeb]);

  useEffect(()=>{
      getFavourtieBooks();
  },[currentBook])

  useEffect(() => {
    getTrendyBooks();
    getRecentBooks();
  },[allBooks]);

  useEffect(() => {
    handleFavouriteBooks();
  },[favourtieBooks]);


  const getTrendyBooks = ()=> {
    if(allBooks){
      const newBooks = allBooks.filter(book => book.trendy == true);
      setTrendyBooks(newBooks);
    }

  }

  const getRecentBooks = () => {
    if (allBooks){
      const newRecentBooks = allBooks.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate)).slice(0, 10);
      setRecentBooks(newRecentBooks);
    }
  }

  const getAllBooks = () => {
    getData(`Book`, {}, "GET").then((res) => {
      if (res?.status !== 200) {
        console.log("failed");
      } else {
        setAllBooks(res.data);
        handleFavouriteBooks();
        setRefreshData(!refreshData);
      }
    });
  }

  const getFavourtieBooks = () => {
    const token = Cookies.get('tokenv2');
    // Decode the token
    if (token){
      const decodedToken = jwtDecode(token);
      const uid = decodedToken.uid;
      getData(`FavoriteBooks/favorites/${uid}`, {}, "GET").then((res) => {
        if (res?.status !== 200) {
          console.log("get favourite failed");
        } else {
          setFavourtieBooks(res.data);
        }
      });
    }
  }

  const handleFavouriteBooks = () => {
    // Create a Set of favourite book IDs for quick lookup
    if (favourtieBooks && allBooks && favourtieBooks.length > 0 && allBooks.length > 0){

      const favouriteBookIds = new Set(favourtieBooks.map(book => book.bookId));
      
      // Iterate through allBooks and set the favourite attribute
      const newAllBooks = allBooks.map(book => ({
        ...book,
        favourite: favouriteBookIds.has(book.bookId)
      }));
      setAllBooks(newAllBooks);
    }
  };

  const getEducationStage = () => {
    getData("EducationStage", {}, "GET").then((res) => {
      if (res.status !== 200) {
        console.log("fetch failed 001");
      } else {
        let newData = res.data.map((data) => ({
          value: data.educationStageId,
          label: data.stageName,
        }));
        newData.unshift({
          value: "",
          label: " كل المراحل",
        });
        setEducationStage(newData);
      }
    });
  };

  const getSubject = () => {
    getData("Subject", {}, "GET").then((res) => {
      if (res.status !== 200) {
        console.log("fetch failed 001");
      } else {
        let newData = res.data.map((data) => ({
          value: data.subjectId,
          label: data.subjectName,
        }));
        newData.unshift({
          value: "",
          label: "كل المواد",
        });
        setSubject(newData);
      }
    });
  };

  const getLevel = () => {
    getData("Level", {}, "GET").then((res) => {
      if (res.status !== 200) {
        console.log("fetch failed 001");
      } else {
        let newData = res.data.map((data) => ({
          value: data.levelId,
          label: data.levelName,
        }));
        newData.unshift({
          value: "",
          label: "كل السنين",
        });
        setLevel(newData);
      }
    });
  };

  const getCategory = () => {
    getData("Category", {}, "GET").then((res) => {
      if (res.status !== 200) {
        console.log("fetch failed 001");
      } else {
        let newData = res.data.map((data) => ({
          value: data.categoryId,
          label: data.categoryName,
        }));
        newData.unshift({
          value: "",
          label: "كتاب & ملازم ",
        });
        setCategory(newData);
      }
    });
  };

  const getAuthor = () => {
    getData("Author", {}, "GET").then((res) => {
      if (res.status !== 200) {
        console.log("fetch failed 001");
      } else {
        let newauthors = res.data.map((author) => ({
          value: author.authorId,
          label: author.authorName,
        }));
        newauthors.unshift({
          value: "",
          label: "الكتب ",
        });
        setAuthor(newauthors);
      }
    });
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setSelectCategory={setSelectCategory}
              trendyBooks={trendyBooks}
              recentBooks={recentBooks}
              allBooks={allBooks}
              setCurrentBook={setCurrentBook}
              cartNumber={cartItems.length}
            />
          }
        />
        <Route
          path="/categories/:id"
          element={
            <Categories
              setCurrentBook={setCurrentBook}
              SelectCategory={SelectCategory} 
              subject={subject}
              author={author}
              allBooks={allBooks}
              cartNumber={cartItems.length}
              refreshData={refreshData}
            />
          }
        />
        <Route path="/favourite" element={<Favourite cartNumber={cartItems.length} favourtieBooks={favourtieBooks} setFavourtieBooks={setFavourtieBooks} cartItems={cartItems} setCartItems={setCartItems}  />} />
        <Route path="/cart" element={<Cart cartNumber={cartItems.length} cartItems={cartItems} setCartItems={setCartItems} refreshData={refreshData} />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        <Route path="/register" element={<PublicRoute element={<Register />} />} />
        {/* <Route path="/register" element={<Register />} /> */}

        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/restPassword" element={<RestPassword />} />
        <Route path="/verifyResetCode" element={<VerifyResetCode />} />
        <Route path="/changePassword" element={<ChangePassword />} />

        <Route
          path="/bookdetails/:id"
          element={<BookDetail allBooks={allBooks} cartNumber={cartItems.length} currentBook={currentBook} setCurrentBook={setCurrentBook} setCartItems={setCartItems} />}
        />
        <Route path="/categotyBooks" element={<CategotyBooks cartNumber={cartItems.length} setSelectCategory={setSelectCategory} />} />
        <Route path="/orderform" element={<Orderform cartNumber={cartItems.length} refreshData={refreshData} />} />
        <Route
          path="/search"
          element={
            <Search
              refreshData={refreshData}
              cartNumber={cartItems.length}
              setCurrentBook={setCurrentBook}
              educationStage={educationStage}
              level={level}
              subject={subject}
              category={category}
              author={author}
              allBooks={allBooks}
            />
          }
        />
        <Route path="/payment-response" element={<Payemnt />} />
        <Route path="/card-payment-response" element={<CardPayemnt />} />
        <Route path="/past-orders" element={<PastOrders />} />
      </Routes>
    </Router>
  );
};

export default App;

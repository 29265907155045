import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import logo from "../Assets/Images/Mkanak-Logo-small.png";

const Payemnt = () => {

  // https://mkanak-eg.com/?id=213118699&pending=false&amount_cents=13350&success=true&is_auth=false&is_capture=false&is_standalone_payment=true&is_voided=false&is_refunded=false&is_3d_secure=false&integration_id=4600723&profile_id=982727&has_parent_transaction=false&order=240392831&created_at=2024-08-30T01%3A01%3A24.526746&currency=EGP&merchant_commission=0&discount_details=%5B%5D&is_void=false&is_refund=false&error_occured=false&refunded_amount_cents=0&captured_amount=0&updated_at=2024-08-30T01%3A01%3A38.497560&is_settled=false&bill_balanced=false&is_bill=false&owner=1807201&merchant_order_id=140&data.message=Transaction+has+been+completed+successfully.&source_data.type=wallet&source_data.pan=01010101010&source_data.sub_type=wallet&txn_response_code=200&hmac=7e32a1d8e19cb8fe90a2b03ca2cecda8ee92fe83e202f378444a95a585a1b3fbfbe8f18ba18508dd3457776d42e54e1b5e25e307e2bacc7573e85265248141ed

  // const location = useLocation();

  // Helper function to get query parameters
  // const getQueryParams = (search) => {
  //   return new URLSearchParams(search);
  // };

  // Get the query parameters from the URL
  // const queryParams = getQueryParams(location.search);
  // const success = queryParams.get('success') === 'true';

  return (
    // <div>
    //   {success ? (
    //     <img src={successImage} alt="Payment Success" />
    //   ) : (
    //     <img src={failureImage} alt="Payment Failure" />
    //   )}
    //   </div>

    
    <div className=' h-screen'>

     {/* navbar */}

    <div className="flex items-center justify-between mb-3 w-full max-w-screen-lg mt-4">
        <Link to="/">
          <img src={logo} alt="Logo" className="w-32 h-auto" />
        </Link>    
        <div className="flex items-center relative">
        </div>
      </div>

      {/* page */}

    <div className="flex flex-col items-center justify-center h-4/6 bg-white">
      <div className="flex items-center justify-center bg-white border-8 border-[#e3b917] rounded-full h-24 w-24 mb-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-16 h-16 text-[#e3b917]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <h1 className="text-2xl font-semibold mb-2">تم ارسال الطلب بنجاح </h1>
      <p className="text-center text-gray-600 mb-8">
        سيتم التواصل معكم خلال ساعات  
      </p>
      <Link to="/" className="px-6 py-2 bg-[#e3b917] text-white rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400">
        تم
      </Link>
    </div>
    <div className=' mt-auto'>
        <Footer/>
      </div>
    </div>
  );
};

export default Payemnt;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import sendData from '../Api/Data';

const CardPayemnt = () => {

    const [payStatus,setPayStatus] = useState("waiting");
    const [message,setMessage] = useState("");
    // waiting , success , fail 

  // https://mkanak-eg.com/?id=213118699&pending=false&amount_cents=13350&success=true&is_auth=false&is_capture=false&is_standalone_payment=true&is_voided=false&is_refunded=false&is_3d_secure=false&integration_id=4600723&profile_id=982727&has_parent_transaction=false&order=240392831&created_at=2024-08-30T01%3A01%3A24.526746&currency=EGP&merchant_commission=0&discount_details=%5B%5D&is_void=false&is_refund=false&error_occured=false&refunded_amount_cents=0&captured_amount=0&updated_at=2024-08-30T01%3A01%3A38.497560&is_settled=false&bill_balanced=false&is_bill=false&owner=1807201&merchant_order_id=140&data.message=Transaction+has+been+completed+successfully.&source_data.type=wallet&source_data.pan=01010101010&source_data.sub_type=wallet&txn_response_code=200&hmac=7e32a1d8e19cb8fe90a2b03ca2cecda8ee92fe83e202f378444a95a585a1b3fbfbe8f18ba18508dd3457776d42e54e1b5e25e307e2bacc7573e85265248141ed

  useEffect(() => {

    const fullUrl = window.location.href;
    
    const baseUrl = 'https://mkanak-eg.com/card-payment-response/';
    
    const paramsString = fullUrl.substring(fullUrl.indexOf(baseUrl) + baseUrl.length);
    
    sendData(`Payment/redirect/${paramsString}`, {}, "GET").then((res) => {
        if (res.data.message == "تم رفض العمليه") {
            setPayStatus("fail");
            setMessage("تم رفض العمليه");
        } else if (res.data.message=="تم الدفع بنجاح") {
            setPayStatus("success");
            setMessage("تم الدفع بنجاح");
        } else {
            setPayStatus("fail");
            setMessage(res.data.message);
        }
        
      });
    
  }, []);

  return (

    
    <div className=' h-screen'>

     {/* navbar */}

    <div className="flex items-center justify-between mb-3 w-full max-w-screen-lg mt-4">
        <Link to="/">
          <img src={logo} alt="Logo" className="w-32 h-auto" />
        </Link>    
        <div className="flex items-center relative">
        </div>
      </div>

      {/* page */}

    {
        <div className="flex flex-col items-center justify-center h-4/6 bg-white">
      {payStatus=="success"&&<div className="flex items-center justify-center bg-white border-8 border-[#e3b917] rounded-full h-24 w-24 mb-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-16 h-16 text-[#e3b917]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    }
      {payStatus=="fail"&&<div className="flex items-center justify-center bg-white border-8 border-[#e31717] rounded-full h-24 w-24 mb-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 text-[#e31717]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
      }
      {payStatus=="waiting"&&<div className="flex items-center justify-center rounded-full h-24 w-24 mb-6">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className="animate-spin w-16 h-16 text-[#e3b917]"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
    ></path>
  </svg>
</div>
      }

      <h1 className="text-2xl font-semibold mb-2"> {message} </h1>
      {payStatus=="success" && <p className="text-center text-gray-600 mb-8">
        سيتم التواصل معكم خلال ساعات  
      </p>}
      {payStatus != "waiting" && <Link to="/" className="px-6 py-2 bg-[#e3b917] text-white rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400">
        تم
      </Link>}
        </div>
    }

    <div className=' mt-auto'>
        <Footer/>
      </div>
    </div>
  );
};

export default CardPayemnt;

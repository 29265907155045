import React from 'react'
import logo from "../Assets/Images/genuisLogo.png";

function Footer() {
  return (
    <div className="bg-gray-200 rounded-lg shadow m-4 mb-[80px] mt-[40px]">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-900 sm:text-center">© 2023 <a href="https://www.facebook.com/profile.php?id=61559694659595&mibextid=ZbWKwL" className="hover:underline text-yellow-700">Genuis Web</a>. All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-900 sm:mt-0">
            <span className=' mx-1'> بالتعاون بين  </span>
            <a href="https://www.facebook.com/profile.php?id=100094555561597" className="hover:underline md:me-6 text-yellow-700">  كوكب مكانك  </a>
            <span className=' mx-1'> و </span>
            <a href="https://www.facebook.com/profile.php?id=61559694659595&mibextid=ZbWKwL" > 
                <span className=' text-yellow-700 inline-block '> Genuis web  </span>
                <img className='h-10 w-10 rounded-full inline-block ' src={logo} />
            </a>
        </ul>
        <p className=' text-xs text-gray-500 flex flex-row '> <a href='https://wa.me/201288603365' className='mx-2 mt-4'> رقم التواصل  : 01288603365  </a>  </p>
        <p className=' text-xs text-gray-500 flex flex-row '> <a href='https://wa.me/201069306967' className='mx-2 mt-1'>  رقم للتواصل : 01069306967  </a>  </p>
        <p className=' text-xs text-gray-500 flex flex-row '> <a href='https://wa.me/201557428919' className='mx-2 mt-1'>  للخدمات البرمجية : 01557428919  </a>  </p>
        </div>
    </div>
  )
}

export default Footer

